import React, { useState } from 'react';
import CryptoJS from 'crypto-js';
import Dropzone from 'react-dropzone';
// import { Container, Box, TextField, Select, MenuItem, Button, TextareaAutosize, Typography, AppBar, Toolbar, IconButton, Menu } from '@mui/material';
import { Container, Box, MenuItem, Typography, AppBar, Toolbar, IconButton, Menu } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { saveAs } from 'file-saver';
import ReactPlayer from 'react-player';
import './App.css';

const App = () => {
  const [encryptKey, setEncryptKey] = useState('');
  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState('');
  const [encryptedData, setEncryptedData] = useState('');
  const [decryptedData, setDecryptedData] = useState(null);
  const [fileFormat, setFileFormat] = useState('PNG');
  const [anchorEl, setAnchorEl] = useState(null);

  const handleEncryptKey = (e) => {
    setEncryptKey(e.target.value);
  };

  const handleFileFormatChange = (e) => {
    setFileFormat(e.target.value);
  };

  const handleImageUpload = async (acceptedFiles) => {
    const selectedFile = acceptedFiles[0];
    setFile(selectedFile);
    setFileName(selectedFile.name); // Extracting file name
  };

  const handleEncrypt = () => {
    if (!file || !encryptKey) return;

    const reader = new FileReader();
    reader.onload = () => {
      // Check if the file size is within the limit (e.g., 100 MB)
      const fileSizeLimit = 100 * 1024 * 1024; // 100 MB
      if (reader.result.byteLength > fileSizeLimit) {
        console.error('File size exceeds the limit');
        return;
      }

      // Convert the ArrayBuffer to a WordArray
      const wordArray = CryptoJS.lib.WordArray.create(reader.result);

      // Encrypt the WordArray
      const encrypted = CryptoJS.AES.encrypt(wordArray, encryptKey);
      const encryptedBytes = encrypted.toString();
      setEncryptedData(encryptedBytes);
    };
    reader.readAsArrayBuffer(file);
  };


  const handleDecryptKey = (e) => {
    setEncryptKey(e.target.value);
  };

  const handleDecrypt = () => {
    if (!encryptedData || !encryptKey) return;

    const decrypted = CryptoJS.AES.decrypt(encryptedData, encryptKey);
    const decryptedBytes = decrypted.toString(CryptoJS.enc.Latin1);
    const bytes = new Uint8Array(decryptedBytes.length);
    for (let i = 0; i < decryptedBytes.length; i++) {
      bytes[i] = decryptedBytes.charCodeAt(i);
    }
    const blob = new Blob([bytes], { type: `image/${fileFormat.toLowerCase()}` });
    setDecryptedData(URL.createObjectURL(blob));
  };

  const handleDownload = () => {
    if (!decryptedData) return;
    saveAs(decryptedData, `decrypted_image.${fileFormat.toLowerCase()}`);
  };

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <Container maxWidth="md">

      <AppBar position="static">
        <Toolbar>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <img src="https://freeweb3.infura-ipfs.io/ipfs/QmTcn3RaQEaRXkzVLvqLy835jB6ZvaCgfnq8ZBpovPBtuV" target="blank" rel="noopener noreferrer"  alt="Web3 Legal Engineering" style={{ width: '200px', marginRight: '20px' }} />
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Typography variant="h6" sx={{ mr: 2 }}>
                <a href="https://lexautomatico.com/auth/jwt/login?returnTo=%2Fdashboard%2Flegal-resources" target="blank" rel="noopener noreferrer" >Dashboard</a>
              </Typography>
              <Typography variant="h6" sx={{ mr: 2 }}>
                <a href="https://artifacts.freeweb3.com" target="blank" rel="noopener noreferrer" >Artifacts</a>
              </Typography>
              <Typography variant="h6">
                <a href="https://web3legaltools.com" target="blank" rel="noopener noreferrer" >Web3 Legal Tools</a>
              </Typography>
              <IconButton
                size="large"
                edge="start"
                color="inherit"
                aria-label="menu"
                sx={{ ml: 2, display: { xs: 'block', sm: 'none' } }}
                onClick={handleMenuClick}
              >
                <MenuIcon />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
              >
                <MenuItem onClick={handleMenuClose}><a href="https://lexautomatico.com/auth/jwt/login?returnTo=%2Fdashboard%2Flegal-resources" target="blank" rel="noopener noreferrer" >Dashboard</a></MenuItem>
                <MenuItem onClick={handleMenuClose}><a href="https://artifacts.freeweb3.com" target="blank" rel="noopener noreferrer" >Artifacts</a></MenuItem>
                <MenuItem onClick={handleMenuClose}><a href="https://web3legaltools.com" target="blank" rel="noopener noreferrer" >Tools</a></MenuItem>
              </Menu>
            </Box>
          </Box>
        </Toolbar>
      </AppBar>

      <Box>

      </Box>
      <Box
        bgcolor="primary.main"
        color="white"
        py={4}
        textAlign="center"
        marginBottom={4}
      >
        <h1alt>Image Encryption for Legal Claims</h1alt>

        <Typography variant="h1" sx={{ fontSize: '24pt', color: 'lightgray', fontWeight: 'bold', textAlign: 'center' }}>
          For Confidential Files
        </Typography>

        <Typography variant="body1" fontFamily="Poppins, sans-serif" mt="10" mb="20px">
          Sensitive claims require images to be confidential.
        </Typography>

        <Box maxWidth="100%" overflow="hidden" mb="25px">
          <img src="https://freeweb3.infura-ipfs.io/ipfs/QmSUYtu76dVGcbRfWNuiuz2DWQXEhVfbZDcFSBKkpxJr9j" target="blank" rel="noopener noreferrer" alt="Encrypted Upload" style={{ width: '100%', height: 'auto', display: 'block' }} />
        </Box>

        <Typography variant="h4" component="p">
          Web3 offers Transparency and Privacy
        </Typography>
      </Box>

      <Box>
        <h1>Image Encryption/Decoding</h1>


        <hr />
        <h2>Encrypt an Image</h2>
        {/* Add Browse button and display selected file name */}

        <input
          type="file"
          onChange={(e) => handleImageUpload(e.target.files)}
        />
        {fileName && <p>Selected File: {fileName}</p>}
        <label htmlFor="fileFormat">Select File Format:</label>

        <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
  <Box width="calc(50% - 10px)">
    <select
      id="fileFormat"
      value={fileFormat}
      onChange={handleFileFormatChange}
      style={{ width: '100%', marginBottom: '10px' }}
    >
      <option value="PNG">PNG</option>
      <option value="JPG">JPG</option>
      <option value="JPEG">JPEG</option>
      <option value="JPEG">GIF</option>
    </select>
  </Box>
  <Box width="calc(50% - 10px)" mr="10px">
    <input
      type="text"
      placeholder="Enter encryption key"
      value={encryptKey}
      onChange={handleEncryptKey}
      style={{ width: '100%', marginBottom: '10px' }}
    />
  </Box>
</Box>


          <Box width="80vw" margin="auto">
            <br />
            <br />
            <Dropzone onDrop={handleImageUpload}>
              {({ getRootProps, getInputProps }) => (
                <div
                  {...getRootProps()}
                  style={{
                    outline: '3px dashed black',
                    background: 'lightgray',
                    padding: '40px',
                    textAlign: 'center',
                  }}
                >
                  <input {...getInputProps()} />
                  <p>Drag and drop an image here, or click to select a file</p>
                </div>
              )}
            </Dropzone>
          </Box>

          <br />
          <Box width="80vw" margin="auto">

            <button onClick={handleEncrypt}>Encrypt File</button>

          </Box>

          {encryptedData && (
            <div>
              <h3>Encrypted Data</h3>
              <textarea
                value={encryptedData}
                readOnly
                style={{ width: '100%', height: '150px' }}
              />
            </div>
          )}

        <Box mt={4}>
          <Typography variant="h4" gutterBottom>
            Demonstration Video
          </Typography>
          <ReactPlayer url="https://freeweb3.infura-ipfs.io/ipfs/QmbC2FboyLQwwdrhvs52cde2wr5JEmN1LbcickNk5TvwYv" width="100%" />
        </Box>


        <div>
          <h2>Decode Image</h2>
          <input
            type="text"
            placeholder="Enter decode key"
            value={encryptKey}
            onChange={handleDecryptKey}
          />
          <textarea
            placeholder="Paste encrypted data here"
            value={encryptedData}
            style={{ width: '100%', height: '150px' }}
            onChange={(e) => setEncryptedData(e.target.value)}
          />
          <button onClick={handleDecrypt}>Decode File</button>
          {decryptedData && (
            <div>
              <h3>Decoded Image</h3>
              <img src={decryptedData} alt="Decoded" />
              <button onClick={handleDownload}>Download Image</button>
            </div>
          )}
        </div>
      </Box>

      <Box mt={4} p={2} bgcolor="primary.main" color="white">
        <Box display="flex" justifyContent="center" alignItems="center">
          <img src="https://freeweb3.infura-ipfs.io/ipfs/QmPQKEC6KVX72Pf1qK4h2jg8xCNWLbzWbSjupruAfCzsi1" alt="Get Id" style={{ width: '100px', margin: '0 10px' }} />
          <img src="https://freeweb3.infura-ipfs.io/ipfs/QmcTYpyFLE8HhjhAwFnR4Pju4t1UcTUKmw1euud4FZj3Aq" alt="Encrypt an ImageFile" style={{ width: '100px', margin: '0 10px' }} />
          <img src="https://freeweb3.infura-ipfs.io/ipfs/QmStCNFtYrAXEzDeTHMzbj8nVFwhbeW4idquz1bKae2umQ" alt="Encrypt to IPFS" style={{ width: '100px', margin: '0 10px' }} />
          <img src="https://freeweb3.infura-ipfs.io/ipfs/QmNuLvC6twHT9c9yw84U6xLT2XqCtF3SvhXJACjTiVdDtX" alt="CID to Chain" style={{ width: '100px', margin: '0 10px' }} />
          <img src="https://freeweb3.infura-ipfs.io/ipfs/QmbVpu2jZFp7FTvkbesMMQJmVAoBdCBtZYtV75o8bcMn1e" alt="Digital Artifacts" style={{ width: '100px', margin: '0 10px' }} />
        </Box>
      </Box>

      <Box mt={2} p={2} bgcolor="primary.main" color="white">
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="body1">
            <a href="https://lexautomatico.com" target="_blank" rel="noopener noreferrer">LexAutomatico</a>
          </Typography>
          <Typography variant="body1" sx={{ flexGrow: 1, textAlign: 'center' }}>
            <a href="https://lex.freeweb3.com" target="_blank" rel="noopener noreferrer">SmartLaw</a>
          </Typography>
          <Typography variant="body1" sx={{ marginLeft: 'auto' }}>
            <a href="https://web3legaltools.com" target="_blank" rel="noopener noreferrer">Web3 Legal Tools</a>
          </Typography>
        </Box>
      </Box>


    </Container>
  );
};

export default App;
